@import '~bootstrap/scss/bootstrap';
@import '~jquery-ui/themes/base/core.css';
@import '~jquery-ui/themes/base/button.css';
@import '~jquery-ui/themes/base/dialog.css';
@import '~jquery-ui/themes/base/datepicker.css';
@import '~jquery-ui/themes/base/theme.css';
@import '~bootstrap4c-chosen/dist/css/component-chosen.css';
@import '~jsgrid/dist/jsgrid-theme.css';
@import '~jsgrid/dist/jsgrid.css';
@import './styles/images.scss';

html,
body {
    height: 100%;
}

// when we remove handlebars move css to components
.disabled {
    opacity: 0.3;
}

span.sprite-disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.span-middle {
    display: inline-block;
    vertical-align: middle;
}

.table-scroll {
    overflow: auto;
    height: 300px;
}

#shareProjectTable td {
    padding: 5px;
}
#shareProjectTable .control-label {
    padding: 0;
    margin-bottom: 0;
}
#shareProjectTable tr:first-child td:last-child {
    visibility: hidden;
}

.jsgrid-cell {
    word-wrap: break-word;
}

.note-label {
    margin: 5px 0;
    float: right;
}

.note-input {
    max-width: none;
    width: 100%;
}

textarea.note-input {
    height: 200px;
}

.note-display {
    margin: 5px 0;
}

.note-preview-content,
.note-display {
    white-space: pre-wrap;
    word-break: break-word;
}

#previewReleaseNoteTemplate .form-group, #previewRegionLinks .form-group {
    margin-bottom: 0;
}


.collapse-toggle {
    cursor: pointer;
}
.collapse-toggle > .collapse-toggle-name {
    display: inline-block;
    line-height: 32px;
    vertical-align: top;
}
.collapse-toggle > .collapse-toggle-icon {
    @include sprite($sprite-chevron-down);
    display: inline-block;
}
.collapse-toggle.collapsed > .collapse-toggle-icon {
    @include sprite($sprite-chevron-right);
    display: inline-block;
}


#addEditMarketingCampaignDialog .hubs {
    margin-top: 8px;
    height: 325px;
    overflow: auto;
}
#addEditMarketingCampaignDialog .regions-item {
    padding: 0 0 16px 32px;
}
#addEditMarketingCampaignDialog  .region-language-item {
    padding: 8px 0 8px 48px;
}
#addEditMarketingCampaignDialog .regions-item-name
{
    display: inline-block;
    width: 248px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}
#addEditMarketingCampaignDialog .region-language-item-name
{
    display: inline-block;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}
#addEditMarketingCampaignDialog .regions-item-url,
#addEditMarketingCampaignDialog .region-language-item-url
{
    display: inline-block;
    width: 250px;
}
#addEditMarketingCampaignDialog .regions-item-label
#addEditMarketingCampaignDialog .region-language-item-label
{
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    margin-left: 20px;
}
#addEditMarketingCampaignDialog .regions-item-label{
    margin-right: 10px;
}
#addEditMarketingCampaignDialog .regionLinksLanguages {
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
#addEditMarketingCampaignDialog .regions-item-enabled
{
    margin-top: 35px;
}


.margin-bottom-10 {
    margin-bottom: 10px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-30 {
    margin-top: 30px;
}


#regionLinksEditForm .learning-image
{
    vertical-align: middle;
    border-style: none;
    width: 224px;
    height: 96px;
    min-width: 220px;
    max-height: 130px;
}
