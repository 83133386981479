﻿@import '../bundles/main.scss';

@mixin sprite($sprite) {
    $sprite-image: nth($sprite, 9);
    $sprite-image-bare: str-slice($sprite-image, 1);
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);

    background-image: url($sprite-image-bare);
    background-position: $sprite-offset-x $sprite-offset-y;
    width: nth($sprite, 5);
    min-width: nth($sprite, 5);
    height: nth($sprite, 6);
}
