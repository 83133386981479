@import '../bundles/main.scss';
@import 'sprites.scss';

@mixin create-sprites($sprites) {
    @each $sprite in $sprites {
        .sprite.#{nth($sprite, 10)} {
            @include sprite($sprite)
        }
    }
}

.sprite {
    display: block;
}

@include create-sprites($spritesheet-main-sprites);
